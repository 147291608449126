<template>
	<div
		class="flex items-center justify-center pt-1 border shadow-md cursor-pointer border-alert-yellow/60 bg-alert-yellow min-h-20"
		@click="toggleExpand"
	>
		<slot name="default">
			<div class="max-w-md text-sm font-bold text-center transition-all duration-150 ease-in-out md:text-base md:max-w-4xl lg:text-lg">
				<div class="flex justify-center space-x-2">
					<div :class="{ 'line-clamp-1': !isExpanded }">
						{{ title }}
					</div>
				</div>
				<div
					:class="[
						{ 'line-clamp-3': !isExpanded },
						'text-xs md:text-sm'
					]"
				>
					{{ message }}
				</div>
				<div class="flex justify-center w-full">
					<ChevronDownIcon
						:class="[
							{ 'transform rotate-180': isExpanded },
							'w-5 h-5'
						]"
					/>
				</div>
			</div>
		</slot>
	</div>
</template>

<script setup lang="ts">
import type { AlertProps } from '@/types/alert'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
	alert: {
		type: Object as PropType<AlertProps>,
		required: true
	},
	expanded: {
		type: Boolean,
		default: false
	}
})

const { expanded } = toRefs(props)

const { title, message } = toRefs(props.alert)

const isExpanded = ref(false)

const toggleExpand = () => {
	isExpanded.value = !isExpanded.value
}

watch(expanded, (value) => {
	if (value) {
		toggleExpand()
	}
}, { immediate: true })
</script>
